import SocialIcons from "./SocialIcons";
import Subscribe from "./Subscribe";

/**
 * Represents the footer section of the website.
 *
 * @component
 */

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className="container">
      <div className="row-divider"></div>
      {/* Social icons */}
      <Subscribe />
      <SocialIcons />

      <span>May Shin Lyan &copy; {currentYear} </span>
    </footer>
  );
};

export default Footer;
