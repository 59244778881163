import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useState } from "react";
import validator from "email-validator";
import Button from "./Button";

const Subscribe = () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [failed, setFailed] = useState(false);
  const [emailError, setEmailError] = useState(false);
  // State for form data
  const [formData, setFormData] = useState({
    email: "",
    access_key: process.env.REACT_APP_ACCESS_KEY,
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // Handle input focus to reset error state
  const handleInputFocus = (errorStateSetter) => {
    errorStateSetter(false);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate and set error states
    formData.email === "" || !validator.validate(formData.email)
      ? setEmailError(true)
      : setEmailError(false);

    // Handle invalid form
    if (emailError || !validator.validate(formData.email)) {
      setFormData({
        ...formData,
        email: "",
      });
      setSending(false);
      setFailed(true);
      return;
    }

    // Form submission in progress
    setSending(true);

    const data = JSON.stringify(formData);

    // Send form data to an API endpoint
    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        // Form submission success
        setSending(false);
        setSuccess(true);
        setFailed(false);
        setFormData({
          ...formData,
          email: "",
        });
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        // Form submission failed
        console.log(err);
        setSending(false);
        setFailed(true);
      });
  };

  // Determine button text based on status
  const handleButtonText = () => {
    if (sending) {
      return "Submitted";
    } else if (success) {
      return "Submitted";
    } else if (failed || emailError) {
      return "Try again";
    } else {
      return "Join";
    }
  };

  return (
    <motion.form
      action=""
      ref={ref}
      className="contactForm"
      initial={{ y: "10vw", opacity: 0 }}
      animate={inView ? { y: 0, opacity: 1 } : { y: "10vw", opacity: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      onSubmit={handleSubmit}
    >
      <h2
        className="contentTitle"
        style={{ fontFamily: "Nunito Sans, sans-serif" }}
      >
        Subscribe to My Weekly Digest
      </h2>
      <span className="" style={{ display: "flex", alignItems: "center" }}>
        <div style={{ padding: "1rem" }}>
          <input
            type="text"
            className={`formControl ${emailError ? "formError" : ""}`}
            onFocus={() => {
              handleInputFocus(setEmailError);
            }}
            onChange={handleChange}
            value={formData.email}
            id="contactEmail"
            name="email"
            placeholder={`${
              emailError ? "Please enter a valid email" : "Email"
            }`}
            autoComplete="email"
            style={{ margin: "0 !important" }}
          />
        </div>
        <Button
          name={handleButtonText()}
          disabled={emailError || sending || success}
          style={{ margin: "0 !important" }}
        />
      </span>
    </motion.form>
  );
};

export default Subscribe;
