import { useEffect } from "react";
import Hero from "../../components/Hero";
import LightDarkToggle from "../../components/LightDarkToggle";

/**
 * Represents the Landing page component.
 * Displays the main landing page content including Hero, Drawing, and About sections.
 *
 * @component
 * @param {string} name - The name of the user displayed in the Hero section.
 */

const Landing = ({ name }) => {
  // Inline styles for the main landing container
  const styles = {
    landing: {
      height: "calc(100% - 93px)",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
  };

    // Apply Dark Mode Theme
    useEffect(() => {
      const colors =  {
        // Dark Colors
        "--bg-color": "#59514A",
        "--bg2-color": "#F2E7DC",
        "--hl-color": "#F2E7DC",
        "--hl2-color": "#59514A",
        "--text-color": "#F2DAC4",
        "--secondary-text-color": "#59514A",
        "--grey": "#9f9f9f76",
        "--highlight-color":"#dc6c3a"
      };

      for (const property in colors) {
        const value = colors[property];
        document.documentElement.style.setProperty(property, value);
      }
    });

  return (
    <>
      {/* Main Landing Page */}
      <main className="landing container" style={styles.landing}>
        <LightDarkToggle isLightMode="false"/>
        {/* Display the hero component */}
        <Hero name={name} />
      </main>

      {/* Display the about section */}
      {/* <About /> */}
    </>
  );
};

export default Landing;
