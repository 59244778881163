import PageHeader from "../../components/PageHeader";
import Image from "../../components/Image";
import AboutPic from "../../images/about.jpg";
import Footer from "../../components/Footer";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const About = ({ brand }) => {
  const [bookRef, bookInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [natureRef, natureInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [aboutDetailsRef, aboutDetailsInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const variants = {
    hidden: { y: "10vw", opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  return (
    <>
      <section className="about container">
        <PageHeader
          title="About Me"
          description="Learn more about me here."
          isLightMode="true"
        />

        <p className="brand">{brand}</p>
        <motion.div
          ref={aboutDetailsRef}
          className=""
          variants={variants}
          initial="hidden"
          animate={aboutDetailsInView ? "visible" : "hidden"}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="row container">
            <div className="col-12 col-xl-4">
              <Image src={AboutPic} alt="May Shin Lyan" />
            </div>
            <div className="col-12 col-xl-8 aboutDetails">
              <p>
                Hey, I am May. I am an entrepreneur, coder, and creative
                individual.
              </p>

              <p>
                I am passionate about using technology and design-friendly
                aesthetics to bring positive changes to the world. I care about
                people, the products I create, and the impact they have on the
                world.
              </p>

              <p>
                I currently work full time at Google as a Google Cloud Solution
                Consultant. I have co-founded{" "}
                <a
                  href="https://www.bridgeburma.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  BridgeBurma
                </a>
                , an innovative educational platform, providing study abroad and
                scholarship opportunities to Myanmar youth.
              </p>

              <p>
                During my free time, I like traveling around the world,
                exploring nature and the diverse human cultures. I like to stay
                active by doing yoga, pilates, gym, and running.
              </p>

              <p>
                I value authenticity, compassion, and personal growth. If you
                resonate with any of these or want to work with me on cool
                projects, my inbox is always open. Home is in San Mateo but I
                travel to Washington DC time to time.
              </p>
            </div>
          </div>
        </motion.div>

        <div className="row about-section">
          <motion.div
            ref={bookRef}
            className=""
            variants={variants}
            initial="hidden"
            animate={bookInView ? "visible" : "hidden"}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <h1 className="mb-5">~ books ~</h1>
            <ul>
              <li>
                <motion.i className="fa-solid fa-book-open"></motion.i>
                &nbsp;The Design of Everyday Things by Don Norman
              </li>
              <li>Courage to Dislike by Ichiro Kishimi, Fumitake Koga</li>
              <li>Rich Dad Poor Dad by Robert Kiyosaki</li>
              <li>7 Habits of Highly Effective People by Stephen Covey</li>
              <li>Atomic Habits by James Clear</li>
            </ul>
          </motion.div>
        </div>

        <div className="row about-section">
          <motion.div
            ref={natureRef}
            className=""
            variants={variants}
            initial="hidden"
            animate={natureInView ? "visible" : "hidden"}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <h1 className="mb-5">~ nature ~</h1>
            <ul>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Yellowstone
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Yosemite
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Grand Canyon
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Zion
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Joshua Tree
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Olympic
                National Park
              </li>
              <li>
                {" "}
                <motion.i className="far fa-circle"></motion.i> Rocky Mountain
                National Park
              </li>
              <li>
                <motion.i className="far fa-circle"></motion.i> Acadia National
                Park
              </li>
              <li>
                <motion.i className="far fa-circle"></motion.i> Great Smoky
                Mountains National Park
              </li>
              <li>
                <motion.i className="far fa-circle"></motion.i> Glacier National
                Park
              </li>
            </ul>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
