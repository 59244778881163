import { useState } from "react";

/**
 * Represents an image component with loading spinner.
 *
 * @component
 * @param {string} src - The source URL of the image.
 * @param {string} height - The height of the image container.
 * @param {string} width - The width of the image container.
 * @param {string} size - The size of the loading spinner.
 * @param {string} alt - The alternative text for the image.
 * @param {string} opacity - The opacity of the image.
 */

export default function Image({
  src,
  height,
  width,
  size,
  alt,
  opacity,
  vintage,
}) {
  const styles = {
    vintageContainer: {
      position: "relative",
      display: "inline-block",
    },
    vintageImage: {
      opacity: opacity ? opacity : "1",
      width: "100%",
      height: "auto",
      filter: "sepia(0.5) contrast(1) brightness(0.9) ",
      borderRadius: "0.5em",
    },
    vintageOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 223, 186, 0.3)", // Light warm beige color
      mixBlendMode: "multiply",
    },
    vintageNoise: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage:
        'url("https://www.transparenttextures.com/patterns/asfalt-dark.png")', // Use any transparent noise texture
      opacity: 0.2, // Adjust for subtlety
      pointerEvents: "none",
    },
  };
  // State to manage image loading status
  const [loading, setLoading] = useState(true);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: width ? width : "100%",
        height: height ? height : "100%",
      }}
    >
      {vintage ? (
        <>
          <img
            src={src}
            className="fadeIn"
            style={styles.vintageImage}
            onLoad={(e) => {
              setLoading(false); // Set loading to false when the image is loaded
            }}
            alt={alt} // Alt text for the image
          />
          {/* <div className="vintage-overlay" style={styles.vintageOverlay}></div> */}
          <div className="vintage-noise" style={styles.vintageNoise}></div>
        </>
      ) : (
        <img
          src={src}
          className="fadeIn"
          style={{
            display: loading ? "none" : "block",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: opacity ? opacity : "1",
            borderRadius: "0.5em",
          }}
          onLoad={(e) => {
            setLoading(false); // Set loading to false when the image is loaded
          }}
          alt={alt} // Alt text for the image
        />
      )}

      {/* Loading spinner */}
      <div
        className="spinner"
        style={{
          display: loading ? "block" : "none",
          fontSize: size ? size : "24px",
        }}
      ></div>
    </div>
  );
}
