import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import Image from "./Image";

const BlogCard = ({ title, image, id, desc, date }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const variants = {
    hidden: { y: "10vw", opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <div key={id} className="blogCard">
      <motion.div
        ref={ref}
        className=""
        variants={variants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          onClick={() => {}}
        >
          <div className="textWrap d-flex flex-column justify-content-center align-items-center m-5">
            <h2 className="blogTitle">{title}</h2>
            <h4 className="blogAuthor">
              by <a href="./about">May Shin Lyan</a>
            </h4>
            <div className="align-items-center justify-content-center w-50">
              <Image src={image} alt="blog image" vintage="true" />
            </div>
            <div className="blogDesc">{desc}</div>
            <Link to={`/blog/${title.toLowerCase()}`} className="viewWork">
              Read more... <FiArrowUpRight />
            </Link>
          </div>
        </div>
      </motion.div>
      <span className="row-divider"></span>
    </div>
  );
};

export default BlogCard;
