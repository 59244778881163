import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";

/**
 * Represents the 404 Page Not Found component.
 * This component is displayed when a user tries to access a non-existent page.
 *
 * @component
 */

const ComingSoon = () => {
  return (
    <main className="error">
      {/* Display the page header */}
      <PageHeader title="🍵" description="Still crafting..." />

      <div className="error-description">
        <div className="row">
          <div className="col">
            {/* Display a message indicating the page was not found */}
            <p>The page you are looking for is still brewing.</p>
            <p>Please check back again in a few days.</p>

            {/* Provide a link back to the home page */}
            <Link to="/" className="home">
              <Button name="Return to Home" />
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ComingSoon;
