import { motion } from "framer-motion";

/**
 * Represents a component displaying social media icons.
 *
 * This component displays social media icons with animation effects.
 *
 * @component
 */

const SocialIcons = () => {
  // Define styles for the icons
  const styles = {
    icon: {
      textDecoration: "none",
      fontSize: "22px",
      padding: "10px",
      transition: "0.2s ease-in",
    },
    socialIcons: {
      position: "absolute",
      zIndex: "100",
      display: "flex",
      flexDirection: "column",
      bottom: "4vh",
      right: "5vh",
    },
  };

  return (
    <div className="socialIcons" style={styles.socialIcons}>
      <a
        className="icon"
        style={styles.icon}
        href="https://www.linkedin.com/in/mayshinlyan/"
        target="_blank"
        rel="noreferrer"
      >
        {/* LinkedIn Icon */}
        <motion.i
          initial={{ y1: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.5, type: "spring" }}
          className="fa-brands fa-linkedin"
          aria-hidden="true"
          title="May Shin Lyan' LinkedIn Profile"
        ></motion.i>
      </a>
      <a
        className="icon"
        style={styles.icon}
        href="https://www.instagram.com/lotusmellow/"
        target="_blank"
        rel="noreferrer"
      >
        {/* Instagram Icon */}
        <motion.i
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5, type: "spring" }}
          className="fa-brands fa-instagram"
          aria-hidden="true"
          title="May Shin Lyan' Instagram Profile"
        ></motion.i>
      </a>

      <a
        className="icon"
        style={styles.icon}
        href="https://www.youtube.com/@lotusmellow"
        target="_blank"
        rel="noreferrer"
      >
        {/* Youtube Icon */}
        <motion.i
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5, type: "spring" }}
          className="fa-brands fa-youtube"
          aria-hidden="true"
          title="May Shin Lyan' Youtube Profile"
        ></motion.i>
      </a>
    </div>
  );
};

export default SocialIcons;
