import ProjectCard from "./ProjectCard";
import projects from "../_data/projects.json";

/**
 * Represents a list of project cards.
 *
 * This component maps over the projects data and generates
 * a ProjectCard component for each project.
 *
 * @component
 */

const ProjectList = () =>
  projects.map((project) => (
    <ProjectCard
      key={project.id}
      title={project.title}
      image={project.image}
      color={project.bgcolor}
      url={project.url}
    />
  ));

export default ProjectList;
