import { motion } from "framer-motion";
import landingImage from "../images/suit.png";
import SocialIcons from "./SocialIcons";

/**
 * Represents the hero section of the page.
 *
 * @component
 * @param {string} name - The name to be displayed in the hero section.
 */

const Hero = ({ name }) => {
  // Styles for various elements
  const styles = {
    hero: {
      position: "relative",
      boxShadow: "0 0 200px rgba(0,0,0,0.9) inset",
    },
    landingImage: {
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "1",
    },
    vignetteOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "radial-gradient(circle, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 100%)",
      pointerEvents: "none", // Ensures overlay doesn’t block interactions
      zIndex: "2",
    },

    textContainer: {
      position: "absolute",
      zIndex: "100",
      color: "#F2E7DC",
      marginTop: "30vh",
      left: "7em",
    },

    role: {
      fontSize: "3em",
      textAlign: "left",
      padding: "0.5em",
      textShadow: "1px 1px 3px #000",
    },
  };
  return (
    <>
      <div className="hero container">
        {/* Animated landing image */}
        <motion.img
          className="landingImage"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          style={styles.landingImage}
          src={landingImage}
          alt="May Shin Lyan"
        />
        <div style={styles.vignetteOverlay}></div>

        {/* Displaying social icons */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <SocialIcons />
        </motion.div>

        <div className="textContainer" style={styles.textContainer}>
          {/* Animated name */}
          <motion.h1
            style={styles.role}
            initial={{ scale: 0.1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 1.6, duration: 0.2, type: "easeInOut" }}
          >
            Designer. &nbsp; Coder. &nbsp; Entrepreneur.
          </motion.h1>
        </div>
      </div>
    </>
  );
};

export default Hero;
