import BlogCard from "./BlogCard";
import blogs from "../_data/blogs.json";

const BlogList = () =>
  blogs.map((blog) => (
    <BlogCard
      key={blog.id}
      title={blog.title}
      image={blog.image}
      date={blog.date}
      desc={blog.desc}
    />
  ));

export default BlogList;
