import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import BlogList from "../../components/BlogList";
import Footer from "../../components/Footer";

const Blog = () => {
  // Get the current location using React Router's useLocation hook
  const location = useLocation();

  // Scroll to the top of the page when the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* Main Portfolio Page */}
      <main className="portfolio container ">
        {/* Display the page header */}
        <PageHeader
          title="Blog"
          description="Read my writings"
          isLightMode="true"
        />

        <div className="row">
          {/* Display the list of blogs */}
          <BlogList />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Blog;
