import { useEffect } from "react";

/**
 * Represents a light/dark mode toggle button component.
 *
 * @component
 */

const LightDarkToggle = (islightMode) => {
  // Apply the selected mode's colors using CSS custom properties
  useEffect(() => {
    const colors = islightMode
      ? {
          // Light Colors
          "--bg-color": "#fff",
          "--bg2-color": "#F2E7DC",
          "--hl-color": "#59514A",
          "--hl2-color": "#736D68",
          "--text-color": "#333",
          "--secondary-text-color": "#F2DAC4",
          "--grey": "#593C36",
          "--highlight-color": "#dc6c3a",
        }
      : {
          // Dark Colors
          "--bg-color": "#59514A",
          "--bg2-color": "#736D68",
          "--hl-color": "#F2E7DC",
          "--hl2-color": "#F2DAC4",
          "--text-color": "#F2DAC4",
          "--secondary-text-color": "#59514A",
          "--grey": "#593C36",
          "--highlight-color": "#dc6c3a",
        };

    for (const property in colors) {
      const value = colors[property];
      document.documentElement.style.setProperty(property, value);
    }
  }, [islightMode]);

  return <></>;
};

export default LightDarkToggle;
