import { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import PageHeader from "../../../components/PageHeader";
import PageNotFound from "../../404/PageNotFound";
import Button from "../../../components/Button";
import Image from "../../../components/Image";
import Articles from "../../../_data/blogs.json";
import Footer from "../../../components/Footer";

/**
 * Represents the ProjectDetails page component.
 * Displays details of a specific project.
 *
 * @component
 */

const BlogDetails = () => {
  // Get the current location using React Router's useLocation hook
  const location = useLocation();

  // Scroll to the top of the page when the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Get the project title from the route parameters
  const { articleTitle } = useParams();

  // Find the project in the data using the title
  const blog = Articles.find(
    (blog) => blog.title.toLowerCase() === articleTitle
  );

  // If the project is not found, display the PageNotFound component
  if (!blog) {
    return <PageNotFound />;
  }

  return (
    <>
      <main className="container portfolio">
        {/* Display the page header with project title and description */}
        <PageHeader title={blog.title} description={blog.description} />

        <div className="projectImage container">
          {/* Display the project image */}
          <Image src={blog.image} alt={blog.name} vintage="true" />
        </div>

        <div className="projectBodyContainer container">
          <div className="projectBody">
            {/* Display project body paragraphs with animation */}
            {blog.body.split("\n").map((paragraph, i) => (
              <motion.p
                className="paragraph"
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  delay: i * 0.3,
                  ease: "easeInOut",
                }}
              >
                {paragraph}
              </motion.p>
            ))}
          </div>

          {/* Display buttons with animation */}
          <motion.div
            style={{ display: "flex", gap: "10px" }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              delay: blog.body.split("\n").length * 0.3,
            }}
          >
            {/* <a href={blog.deployed}>
              <Button name="View Site" />
            </a> */}
            <Link to="/blog">
              <Button name="Go Back" color="var(--hl2-color)" />
            </Link>
          </motion.div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BlogDetails;
